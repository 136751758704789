import "../css/app.css"
import Alpine from "alpinejs"
import { Splide } from "@splidejs/splide"
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll"
import GLightbox from "glightbox"
import "./cookie3.js"

Alpine.start()

document.addEventListener("DOMContentLoaded", function() {
  const elmsGallery = document.getElementsByClassName("splide-gallery")
  for (let i = 0, len = elmsGallery.length; i < len; i++) {
    new Splide(elmsGallery[i], {
      type: "loop",
      lazyLoad: "nearby",
      perPage: 4,
      perMove: 1,
      gap: 30,
      pagination: false,
      autoplay: true,
      arrowPath:
        "m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z",
      breakpoints: {
        1024: {
          perPage: 3,
          gap: 20,
          arrows: false,
          pagination: true
        },
        640: {
          perPage: 2,
          gap: 15,
          arrows: false,
          pagination: true
        }
      }
    }).mount()
  }

  const elmsTeam = document.getElementsByClassName("splide-team")
  for (let i = 0, len = elmsTeam.length; i < len; i++) {
    new Splide(elmsTeam[i], {
      perPage: 6,
      gap: 30,
      arrows: false,
      pagination: false,
      type: "loop",
      drag: "free",
      focus: "center",
      pauseOnHover: false,
      pauseOnFocus: false,

      breakpoints: {
        480: {
          perPage: 1,
          gap: 10
        },
        800: {
          perPage: 2,
          gap: 15
        },
        1280: {
          perPage: 3
        },
        1536: {
          perPage: 4
        },
        1920: {
          perPage: 5
        }
      }
    }).mount({ AutoScroll })
  }

  if (document.querySelector(".glightbox")) {
    GLightbox({
      selector: ".glightbox",
      openEffect: "fade"
    })
  }

  if (document.querySelector(".glightboxSingle")) {
    GLightbox({
      selector: ".glightboxSingle",
      openEffect: "fade"
    })
  }
})
